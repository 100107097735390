import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ButtonColor,
  IAction,
  ImporterColumnDefinition,
  ImporterSheetColumn,
  UnsubscribeOnDestroyAdapter,
} from '@shared/classes';
import { ImporterColumnBindPopupComponent } from '@shared/components/misc/importer-column-bind-popup/importer-column-bind-popup.component';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { omit } from 'lodash-es';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';

declare const luckysheet;
declare const LuckyExcel;

@Component({
  selector: 'app-import-data-page',
  templateUrl: './import-data-page.component.html',
  styleUrls: ['./import-data-page.component.scss'],
})
export class ImportDataPageComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  @ViewChild('container') container: ElementRef<HTMLDivElement>;
  downloadTemplateAction: IAction = {
    label: 'Download Template',
    id: 7,
    color: ButtonColor.Secondary,
    icon: 'pi pi-download',
    iconPos: 'left',
    command: this.exportTemplate.bind(this),
  };
  gridItem: any;
  currentCellIndexes: { rowIndex: number; columnIndex: number } = { rowIndex: null, columnIndex: null };
  draggedElement: ImporterColumnDefinition;
  @Input() colDefinitions: ImporterColumnDefinition[] = [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: true,
        validators: null,
      },
    },
    {
      id: 2,
      type: 'text',
      field: {
        name: 'Alias Name',
        key: 'aliasName',
        required: false,
        validators: null,
      },
    },
    {
      id: 3,
      type: 'text',
      field: {
        name: 'Version Number',
        key: 'versionNumber',
        required: true,
        validators: null,
      },
    },
    {
      id: 4,
      type: 'date',
      field: {
        name: 'Valid From',
        key: 'validFrom',
        required: false,
        validators: null,
      },
    },
    {
      id: 5,
      type: 'date',
      field: {
        name: 'Valid To',
        key: 'validTo',
        required: false,
        validators: null,
      },
    },
    {
      id: 6,
      type: 'text',
      field: {
        name: 'Description',
        key: 'description',
        required: false,
        validators: null,
      },
    },
    // {
    //     id: 2,
    //     type: 'dropdown',
    //     field: {
    //         name: 'Type',
    //         key: 'type',
    //         required: true,
    //         validators: [
    //             {
    //                 "type": "dropdown",
    //                 "type2": null,
    //                 "value1": "Develop,Fix,Done",
    //                 "value2": "",
    //                 "checked": false,
    //                 "remote": false,
    //                 "prohibitInput": false,
    //                 "hintShow": false,
    //                 "hintText": ""
    //             },
    //         ]
    //     }
    // },
    // {
    //     id: 2,
    //     type: 'text',
    //     field: {
    //         name: 'Code',
    //         key: 'code',
    //         required: true,
    //         validators: null
    //     }
    // }
  ];
  dropTargetCols: ImporterSheetColumn[] = [];
  @Input() breadCrumb;
  @Input() pageActions: IAction[] = [
    {
      label: 'Auto Bind',
      id: 1,
      color: ButtonColor.Secondary,
      icon: 'pi pi-link',
      iconPos: 'left',
      command: this.autoBind.bind(this),
      tooltipText: 'Auto Binds First Row and removes it.',
      tooltipPosition: 'top',
    },
    {
      label: 'Bind',
      id: 2,
      color: ButtonColor.Secondary,
      icon: 'pi pi-paperclip',
      iconPos: 'left',
      command: this.showColumnBinding.bind(this),
      tooltipText: 'Manual Column to Field Binding',
      tooltipPosition: 'top',
    },
    {
      label: 'Import Mode',
      id: 4,
      color: ButtonColor.Secondary,
      icon: 'pi pi-arrow-right-arrow-left',
      iconPos: 'left',
      command: this.showDialog.bind(this),
    },
    {
      label: 'Start Import',
      id: 3,
      color: ButtonColor.Primary,
      icon: 'pi pi-save',
      iconPos: 'left',
      command: this.onImport.bind(this),
    },
  ];
  isEditModeModalVisible: boolean = false;
  get isEditMode() {
    return this.isEditModeFormControl.value;
  }
  isEditModeFormControl: FormControl = new FormControl(false);
  @Input() moduleKeyword: string;
  @Input() pageActionsCommandData;
  @Input() requestService: BaseRequestControllerService<any>;
  @Input() extraBodyObjects: any;
  constructor(
    public appDialogService: AppDialogService,
    private ngxCsvParser: NgxCsvParser,
    private exportDataService: ExportDataService
    // private requestService: AuthorityDataService,
  ) {
    super();
    // this.typeRequestService.getList<EntityType>().subscribe({
    //     next:(res)=>{
    //         let list = res.data.map(x=>x.code).join(",");
    //     }
    // })
  }

  ngOnInit(): void {}
  ngAfterViewInit() {
    // const grid = canvasDatagrid({
    //     parentNode: this.container.nativeElement,
    //     data: [{ col1: 'foo', col2: 0, col3: 'a' },
    //     { col1: 'bar', col2: 1, col3: 'b' },
    //     { col1: 'baz', col2: 2, col3: 'c' }]
    // });
    // this.gridItem = grid;

    // const _this = this;
    // this.gridItem.addEventListener('cellmouseover', function (e) {
    //     if (!e.cell) {
    //         return;
    //     }
    //     _this.gridItem.data[0][_this.gridItem.schema[0].name] =
    //         'Just arrived at ' + e.cell.columnIndex + ', ' + e.cell.rowIndex;
    //     _this.currentCellIndexes.columnIndex = e.cell.columnIndex;
    //     _this.currentCellIndexes.rowIndex = e.cell.rowIndex;
    // });
    //Configuration item
    this.showDialog();
  }
  exportTemplate() {
    let template = this.colDefinitions
      .filter((x) => !!!x.field.hideInImporter)
      .map((x) => {
        return {
          [x.field.key]: null,
        };
      });
    this.exportDataService.exportCSV(template, this.moduleKeyword + '' + '_template');
  }
  showDialog() {
    this.isEditModeModalVisible = true;
  }
  closeDialog() {
    this.isEditModeModalVisible = false;
    this.initLuckySheet();
  }
  dragStart(event, element: any) {
    this.draggedElement = element;
  }

  drop(event, index) {
    if (this.draggedElement) {
      this.dropTargetCols[index].boundDefinition = this.draggedElement;
      this.dropTargetCols = [...this.dropTargetCols];
      // let draggedElementIndex = this.findIndex(this.draggedElement);
      // this.selectedElements = [...this.selectedElements, this.draggedElement];
      // this.availableElements = this.availableElements.filter((val, i) => i != draggedElementIndex);
      // this.draggedElement = null;
      // this.initLuckySheet();
    }
  }

  dragEnd(event) {
    this.draggedElement = null;

    luckysheet.setDataVerification(
      {
        type: 'number',
        type2: 'bw',
        value1: '1',
        value2: '10',
        checked: false,
        remote: false,
        prohibitInput: true,
        hintShow: false,
        hintText: '',
      },
      { range: 'A1:A80' }
    );
  }
  onRemoveBinding(index) {
    this.dropTargetCols[index].boundDefinition = null;
    this.dropTargetCols = [...this.dropTargetCols];
  }
  findIndex(data: any) {
    if (data == 'Name') return 0;
  }
  logData(r, c, old, newD, isRefresh) {
    //r,c,old,newD,isRefresh

    this.dropTargetCols = this.getColumnsWithValues(luckysheet.getSheetData());
    const _this = this;
    // if(r == 0){
    //     let colDefIndex = _this.colDefinitions.findIndex(x => x?.field?.key?.toLowerCase() == newD?.v?.toLowerCase() || x?.field?.name?.toLowerCase() == newD?.v?.toLowerCase());
    //     let colDef = colDefIndex != -1 ? _this.colDefinitions[colDefIndex] : null;
    //     let sheetCol = this.dropTargetCols.find(x=> x.colIndex == c);

    //     this.dropTargetCols.forEach((column,index) => {
    //         if(column.colIndex != c && column?.boundDefinition?.field?.key?.toLowerCase() == colDef?.field?.key?.toLowerCase()){
    //             column.boundDefinition = null;
    //             let deleteIndex = colDef?.boundedSheetColumns?.findIndex(x => x.colIndex == column.colIndex);
    //             colDef.boundedSheetColumns.splice(deleteIndex, 1);
    //         }
    //     });

    //     if(sheetCol.boundDefinition.field.key.toLowerCase() != colDef.field.key.toLowerCase()){ // skip if already bounded through other methods
    //         if (!_this.colDefinitions[colDefIndex].boundedSheetColumns) _this.colDefinitions[colDefIndex].boundedSheetColumns = [];
    //         if(_this.colDefinitions[colDefIndex].fieldGroupKey){
    //             if(_this.colDefinitions[colDefIndex].boundedSheetColumns.findIndex(x=> x.id == sheetCol.id) == -1){
    //                 _this.colDefinitions[colDefIndex].boundedSheetColumns.push(sheetCol);
    //             }
    //         }else{
    //             _this.colDefinitions[colDefIndex].boundedSheetColumns = [sheetCol];
    //         }
    //         sheetCol.boundDefinition = _this.colDefinitions[colDefIndex];
    //         const sheetData = this.getRawData(luckysheet.getSheetData());
    //         _this.dropTargetCols.forEach(element => {
    //             if (element.boundDefinition)
    //                 element.validAmount = _this.calcValidationAmount(element.colName, element.boundDefinition, sheetData);
    //         });
    //         this.setDataVerification();
    //     }
    // }
  }
  initLuckySheet(extraData = []) {
    let options = {
      container: 'luckysheet', //luckysheet is the container id
      showtoolbar: false,
      showsheetbar: false,
      userInfo: false,
      showinfobar: false,
      hook: {
        cellUpdated: (r, c, old, newD, isRefresh) => {
          this.logData(r, c, old, newD, isRefresh);
        },
        cellEditBefore: (range) => {
          // setTimeout(() => {
          //     if(range.column_focus < 3){
          //         luckysheet.exitEditMode();
          //     }
          // }, 10);
        },
        sheetCreateAfter: (sheet) => {},
        // cellUpdateBefore: (r,c,value,isRefresh) => {
        //     return false;
        // }
      },
      data: [
        {
          celldata: [
            {
              r: 0,
              c: 0,
              v: {
                ct: { fa: 'General', t: 'g' },
                m: 'Status',
                v: 'Status',
              },
            },
            {
              r: 0,
              c: 1,
              v: {
                ct: { fa: 'General', t: 'g' },
                m: 'code',
                v: 'code',
              },
            },
            ...extraData,
            // {
            //     "r": 0,
            //     "c": 1,
            //     "v": {
            //         ct: { fa: "0", t: "n" },
            //         m: "value2",
            //         v: "value2"
            //     }
            // },
          ],
          config: {
            authority: {
              //Permission configuration of the current worksheet
              selectLockedCells: 1, //Select locked cells
              selectunLockedCells: 1, //Select unlocked cells
              formatCells: 1, //Format cells
              formatColumns: 1, //Format columns
              formatRows: 1, //Format rows
              insertColumns: 1, //Insert columns
              insertRows: 1, //Insert rows
              insertHyperlinks: 1, //Insert hyperlinks
              deleteColumns: 1, //Delete columns
              deleteRows: 1, //Delete rows
              sort: 0, //Sort
              filter: 0, //Filter
              usePivotTablereports: 0, //Use Pivot Table reports
              editObjects: 1, //Edit objects
              editScenarios: 1, //Edit scenarios
              sheet: 1, //If it is 1 or true, the worksheet is protected; if it is 0 or false, the worksheet is not protected.
              hintText: 'Cells Are Locked', //The text of the pop-up prompt
              algorithmName: 'None', //Encryption scheme: MD2,MD4,MD5,RIPEMD-128,RIPEMD-160,SHA-1,SHA-256,SHA-384,SHA-512,WHIRLPOOL
              saltValue: null, //The salt parameter for password decryption is a random value set by yourself

              allowRangeList: [
                {
                  //Range protection
                  algorithmName: 'None',
                  checkRangePasswordUrl: null,
                  hintText: 'Cell Locked',
                  name: 'Allowed Data',
                  password: '',
                  saltValue: null,
                  sqref: this.isEditMode ? '$B$1:$AAA$10000' : '$C$1:$AAA$10000',
                  // sqref: "$A$1:$AAA$10000"
                },
              ],
            },
            // "luckysheet_conditionformat_save": [{
            //     "type": "default",
            //     "cellrange": [{
            //         "row": [1, 10000],
            //         "column": [0, 8]
            //     }],
            //     "format": {
            //         "textColor": "#000000",
            //         "cellColor": "#ff0000"
            //     },
            //     "conditionName": "betweenness",
            //     "conditionRange": [{
            //         "row": [1, 10000],
            //         "column": [0, 8]
            //     }],
            //     "conditionValue": [2, 4]
            // },]
          },
          // "frozen": {
          //     type: 'both'
          // }, //Freeze row and column configuration
        },
      ],
    };

    luckysheet.create(options);
    setTimeout(() => {
      const _this = this;
      // let list = _this.colDefinitions.filter(a => !!!a?.field?.hideInImporter).map(x => x.field.key).join(",");
      // let bindingValidation = {
      //     key: 'dropdown',
      //     value: {
      //         "type": "dropdown",
      //         "type2": null,
      //         "value1": list,
      //         "value2": "",
      //         "checked": false,
      //         "remote": false,
      //         "prohibitInput": true,
      //         "hintShow": false,
      //         "hintText": ""
      //     }
      // }
      // luckysheet.deleteDataVerification({
      //     range: this.isEditMode ? "B1:AAA1" : "C1:AAA1", success: () => {
      //         luckysheet.setDataVerification(bindingValidation.value, { range: (this.isEditMode ? "B1:" : "C1:") + "AAA1" })
      //     }
      // });
      // luckysheet.setRangeConditionalFormatDefault("equal",{ type: 'range', content: ['A1'] })
      let rowLength = luckysheet.getSheetData().length;
      let colLength = luckysheet.getSheetData()?.[0]?.length;

      let colCount = this.isEditMode ? 1 : 2;
      for (let col = 0; col < colLength; col++) {
        luckysheet.setCellFormat(0, col, 'bg', '#e0e0e0', {
          order: 0,
          success: () => {
            luckysheet.setDataVerification(
              {
                type: 'text_length',
                type2: 'eq',
                value1: '0',
                value2: '0',
                checked: false,
                remote: false,
                prohibitInput: true,
                hintShow: false,
                hintText: 'Cell Is Locked',
              },
              { range: this.isEditMode ? 'A2:A1000' : 'A2:B1000' }
            );
            luckysheet.setDataVerification(
              {
                type: 'text_length',
                type2: 'eq',
                value1: '0',
                value2: '0',
                checked: false,
                remote: false,
                prohibitInput: true,
                hintShow: false,
                hintText: 'Cell Is Locked',
              },
              { range: 'B1:AAA1' }
            );
          },
        });
      }
      for (let row = 1; row < rowLength; row++) {
        for (let index = 0; index < colCount; index++) {
          luckysheet.setCellFormat(row, index, 'bg', '#e0e0e0', {
            order: 0,
            success: () => {
              luckysheet.setDataVerification(
                {
                  type: 'text_length',
                  type2: 'eq',
                  value1: '0',
                  value2: '0',
                  checked: false,
                  remote: false,
                  prohibitInput: true,
                  hintShow: false,
                  hintText: 'Cell Is Locked',
                },
                { range: this.isEditMode ? 'A2:A1000' : 'A2:B1000' }
              );
              luckysheet.setDataVerification(
                {
                  type: 'text_length',
                  type2: 'eq',
                  value1: '0',
                  value2: '0',
                  checked: false,
                  remote: false,
                  prohibitInput: true,
                  hintShow: false,
                  hintText: 'Cell Is Locked',
                },
                { range: 'B1:AAA1' }
              );
            },
          });
        }
      }
    }, 10);
    // setTimeout(() => {
    //     luckysheet.setRangeFormat("bg", "#ff0000", { range: ["A1:H10"] })
    // }, 5000);
    // luckysheet.setCellValue(0,0,"Status");
  }
  setColorsForSheet() {
    for (let row = 1; row < 10; row++) {
      for (let index = 2; index < 20; index++) {
        luckysheet.setCellFormat(row, index, 'bg', '#2196f3', { order: 0 });
        // luckysheet.setCellValue(row, index, {
        //     ct: { fa: "General", t: "g" },
        //     m: "EMPTY CELL",
        //     v: "EMPTY CELL",
        //     bg: "#2196f3" //blue
        // });
      }
    }
    // luckysheet.setRangeFormat("bg", "#2196f3", {range:["C12:C70"], order:0})

    for (let row = 1; row < 80; row++) {
      for (let index = 0; index < 2; index++) {
        luckysheet.setCellFormat(row, index, 'bg', '#e0e0e0', { order: 0 });
      }
    }
    setTimeout(() => {
      luckysheet.setConfig(
        {
          authority: {
            //Permission configuration of the current worksheet
            selectLockedCells: 1, //Select locked cells
            selectunLockedCells: 1, //Select unlocked cells
            formatCells: 1, //Format cells
            formatColumns: 1, //Format columns
            formatRows: 1, //Format rows
            insertColumns: 1, //Insert columns
            insertRows: 1, //Insert rows
            insertHyperlinks: 1, //Insert hyperlinks
            deleteColumns: 1, //Delete columns
            deleteRows: 1, //Delete rows
            sort: 0, //Sort
            filter: 0, //Filter
            usePivotTablereports: 0, //Use Pivot Table reports
            editObjects: 1, //Edit objects
            editScenarios: 1, //Edit scenarios
            sheet: 1, //If it is 1 or true, the worksheet is protected; if it is 0 or false, the worksheet is not protected.
            hintText: 'Cells Are Locked', //The text of the pop-up prompt
            algorithmName: 'None', //Encryption scheme: MD2,MD4,MD5,RIPEMD-128,RIPEMD-160,SHA-1,SHA-256,SHA-384,SHA-512,WHIRLPOOL
            saltValue: null, //The salt parameter for password decryption is a random value set by yourself

            allowRangeList: [
              {
                //Range protection
                algorithmName: 'None',
                checkRangePasswordUrl: null,
                hintText: 'Cell Locked',
                name: 'Allowed Data',
                password: '',
                saltValue: null,
                sqref: '$C$1:$AAA$10000',
                // sqref: "$A$1:$AAA$10000"
              },
            ],
          },
        },
        { order: 0 }
      );
      luckysheet.getluckysheetfile()[0].config.authority = {
        //Permission configuration of the current worksheet
        selectLockedCells: 1, //Select locked cells
        selectunLockedCells: 1, //Select unlocked cells
        formatCells: 1, //Format cells
        formatColumns: 1, //Format columns
        formatRows: 1, //Format rows
        insertColumns: 1, //Insert columns
        insertRows: 1, //Insert rows
        insertHyperlinks: 1, //Insert hyperlinks
        deleteColumns: 1, //Delete columns
        deleteRows: 1, //Delete rows
        sort: 0, //Sort
        filter: 0, //Filter
        usePivotTablereports: 0, //Use Pivot Table reports
        editObjects: 1, //Edit objects
        editScenarios: 1, //Edit scenarios
        sheet: 1, //If it is 1 or true, the worksheet is protected; if it is 0 or false, the worksheet is not protected.
        hintText: 'Cells Are Locked', //The text of the pop-up prompt
        algorithmName: 'None', //Encryption scheme: MD2,MD4,MD5,RIPEMD-128,RIPEMD-160,SHA-1,SHA-256,SHA-384,SHA-512,WHIRLPOOL
        saltValue: null, //The salt parameter for password decryption is a random value set by yourself

        allowRangeList: [
          {
            //Range protection
            algorithmName: 'None',
            checkRangePasswordUrl: null,
            hintText: 'Cell Locked',
            name: 'Allowed Data',
            password: '',
            saltValue: null,
            sqref: '$C$1:$AAA$10000',
            // sqref: "$A$1:$AAA$10000"
          },
        ],
      };
    }, 7000);
  }
  getColName(n) {
    var ordA = 'A'.charCodeAt(0);
    var ordZ = 'Z'.charCodeAt(0);
    var len = ordZ - ordA + 1;

    var s = '';
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }
  getColumnsWithValues(data: any[][]) {
    let rowLength = data.length;
    let colLength = data[0].length;
    let colIndexes = [];
    for (let j = this.isEditMode ? 1 : 2; j < colLength; j++) {
      for (let i = 0; i < rowLength; i++) {
        const element = data[i][j];
        if (element?.v != undefined) {
          let existingColIndex = this.dropTargetCols.findIndex((x) => x.colIndex == j);
          if (existingColIndex != -1) {
            colIndexes.push(this.dropTargetCols[existingColIndex]);
          } else {
            colIndexes.push({ id: j, data: null, colName: this.getColName(j), colIndex: j, boundDefinition: null });
          }
          break;
        }
      }
    }
    return colIndexes;
  }
  onImport() {
    this.createList(this.formatData(luckysheet.getSheetData()), 0);
  }
  onExcelFileSelect(file) {
    // Make sure to get the xlsx file first, and then use the global method window.LuckyExcel to convert
    const _this = this;
    this.ngxCsvParser
      .parse(file.currentFiles[0], { header: false, delimiter: ',', encoding: 'utf8' })
      .pipe()
      .subscribe({
        next: (result): void => {
          // this.csvRecords = result;
          let csvData: any[] = [];
          (result as string[][]).forEach((row, ri) => {
            row.forEach((col, ci) => {
              csvData.push({
                r: ri + 1,
                c: ci + 2,
                v: {
                  ct: { fa: 'General', t: 'g' },
                  m: col,
                  v: col,
                },
              });
            });
          });
          this.initLuckySheet(csvData);
          // this.dropTargetCols = this.getColumnsWithValues(csvData);
          this.dropTargetCols = this.getColumnsWithValues(luckysheet.getSheetData());
        },
        error: (error: NgxCSVParserError): void => {},
      });

    // LuckyExcel.transformExcelToLucky(
    //     file.currentFiles[0],
    //     function (exportJson, luckysheetfile) {
    //         // After obtaining the converted table data, use luckysheet to initialize or update the existing luckysheet workbook
    //         // Note: Luckysheet needs to introduce a dependency package and initialize the table container before it can be used
    //         luckysheet.create({
    //             container: 'luckysheet', // luckysheet is the container id
    //             data: exportJson.sheets,
    //             showtoolbar: false,
    //             showsheetbar: false,
    //             userInfo: false,
    //             showinfobar: false,
    //             hook: {
    //                 cellUpdated: (r, c, old, newD, isRefresh) => {
    //                     _this.logData(r, c, old, newD, isRefresh);
    //                 }
    //             },
    //         });
    //     },
    //     function (err) {
    //         console.error('Import failed. Is your file a valid xlsx?');
    //     });
  }
  showColumnBinding() {
    this.dropTargetCols = this.getColumnsWithValues(luckysheet.getSheetData());
    this.appDialogService.showDialog(
      ImporterColumnBindPopupComponent,
      'Bind Fields to Columns',
      (res) => {
        if (res) {
          this.dropTargetCols = res.sheetColumns;
          this.setSheetValidators();
        }
      },
      {
        data: {
          colDefinitions: [...this.colDefinitions],
          sheetColumns: [...this.dropTargetCols],
          sheetData: this.getRawData(luckysheet.getSheetData()),
        },
      }
    );
  }
  setSheetValidators() {
    const _this = this;
    // luckysheet.insertColumn(0);
    // luckysheet.insertRow(0);
    // luckysheet.setHorizontalFrozen(false);
    // luckysheet.setVerticalFrozen(false);
    luckysheet.clearRange({
      range: this.isEditMode ? 'B1:AAA1' : 'C1:AAA1',
      success: () => {
        _this.dropTargetCols.forEach((col) => {
          if (col.boundDefinition) luckysheet.setCellValue(0, col.colIndex, col.boundDefinition.field.key);
        });
      },
    });
    this.setDataVerification();
  }
  setDataVerification() {
    const _this = this;
    luckysheet.deleteDataVerification({
      range: this.isEditMode ? 'B2:AAA10000' : 'C2:AAA10000',
      success: () => {
        _this.dropTargetCols.forEach((element) => {
          element?.boundDefinition?.field?.validators?.forEach((validation) => {
            luckysheet.setDataVerification(validation.value, {
              range: element.colName + '2:' + element.colName + '10000',
            });
          });
        });
      },
    });
  }
  formatData(data: any[][]) {
    let rowLength = data.length;
    let colLength = data[0].length;
    let flatArray = [];
    for (let i = 1; i < rowLength; i++) {
      let rowObj = {};
      let rowHasValue = false;
      this.dropTargetCols.forEach((col) => {
        if (col.boundDefinition) {
          const element = data[i][col.colIndex];
          if (col?.boundDefinition?.isArray && !!!col?.boundDefinition?.isCommaDelimiter) {
            rowObj[col.boundDefinition.field.key] = rowObj[col.boundDefinition.field.key]
              ? [...rowObj[col.boundDefinition.field.key], element?.v]
              : [element?.v];
          } else {
            rowObj[col.boundDefinition.field.key] = element?.v;
          }
          if (element?.v != undefined) {
            rowHasValue = true;
          }
        }
      });
      if (rowHasValue) {
        rowObj['sheetRowIndex'] = i;
        flatArray.push(rowObj);
      }
    }
    return flatArray;
  }
  getRawData(data: any[][]) {
    let rowLength = data.length;
    let colLength = data[0].length;
    let flatArray = [];
    for (let i = 1; i < rowLength; i++) {
      let rowObj = {};
      let rowHasValue = false;
      for (let j = this.isEditMode ? 1 : 2; j < colLength; j++) {
        const element = data[i][j];
        rowObj[this.getColName(j)] = element;
        if (element?.v != undefined) {
          rowHasValue = true;
        }
      }
      if (rowHasValue) {
        flatArray.push(rowObj);
      }
    }
    return flatArray;
  }
  createList(itemList: any[], index: number) {
    if (!itemList || index >= itemList.length) return;

    const item = itemList[index];
    let body = this.extraBodyObjects ? { ...this.extraBodyObjects } : {};
    let skip = false;
    this.colDefinitions.forEach((def) => {
      if (def?.field?.calculationFunction) {
        body[def.field.key] = def?.field?.calculationFunction({
          body: item,
          currentData: body,
          fieldKey: def.field.key,
        });
      } else if (def?.isArray) {
        body[def?.field?.key] = def?.isCommaDelimiter
          ? (item[def?.field?.key] as string).split(def.delimiterType || ',')
          : item[def?.field?.key];
      } else if (def?.fieldGroupKey) {
        body[def?.fieldGroupKey] = {
          ...body[def?.fieldGroupKey],
          [def?.field?.key]: def?.customValueObject
            ? { ...def?.customValueObject?.customObject, [def?.customValueObject?.valueKey]: item[def?.field?.key] }
            : item[def?.field?.key],
        };
      } else {
        body[def?.field?.key] = item[def?.field?.key];
      }

      if (def?.field?.required && !item[def?.field?.key]) skip = true;
      if (def?.field?.required && item[def?.field?.key]) {
        def?.field?.validators?.forEach((validator) => {
          if (validator?.key === 'dropdown' && !validator.value?.value1?.includes(item[def?.field?.key])) skip = true;
          if (validator?.key === 'text_content' && !item[def?.field?.key]?.startsWith(validator?.value?.value1))
            skip = true;
        });
      }
    });

    if (skip) {
      this.updateCellStatus(item, 'EMPTY CELL', '#2196f3'); // Blue
      setTimeout(() => this.createList(itemList, index + 1), 100);
      return;
    }

    this.updateCellStatus(item, 'Sending...', undefined);

    const request$ = this.isEditMode
      ? this.requestService.patchSingleUpdate(this.getDataKeyValueFormat(omit(body, 'code')).createItems, body.code)
      : this.requestService.create(this.getDataKeyValueFormat(body));

    this.subs.sink = request$.subscribe({
      next: (res) => {
        this.updateCellStatus(item, 'SUCCESS', '#3eaf7c'); // Lemon green
        setTimeout(() => this.createList(itemList, index + 1), 100);
      },
      error: (error) => {
        const errMsg = typeof error === 'string' ? error : error.message || 'Request Error';
        this.updateCellStatus(item, `ERROR ${errMsg}`, '#FF0000'); // Red
        setTimeout(() => this.createList(itemList, index + 1), 100);
      },
    });
  }

  private updateCellStatus(item: any, message: string, bgColor?: string) {
    luckysheet.setCellValue(item['sheetRowIndex'], 0, {
      ct: { fa: 'General', t: 'g' },
      m: message,
      v: message,
      bg: bgColor,
    });
  }
  getDataKeyValueFormat(formCurrentData) {
    let createItems: { key: string; value: any }[] = [];
    Object.keys(formCurrentData).forEach((key) => {
      if (formCurrentData[key] != undefined && formCurrentData[key] != null && formCurrentData[key] != '')
        createItems.push({ key: key, value: formCurrentData[key] });
    });
    return { createItems: createItems };
  }
  autoBind() {
    const _this = this;
    const sheetData = this.getRawData(luckysheet.getSheetData());
    luckysheet.clearRange({
      range: this.isEditMode ? 'B1:AAA1' : 'C1:AAA1',
      success: () => {
        _this.dropTargetCols.forEach((sheetCol) => {
          sheetCol.boundDefinition = null;
          sheetCol.validAmount = 0;
          let sheetDataCell = sheetData?.[0]?.[sheetCol.colName];
          if (sheetDataCell && sheetDataCell?.v) {
            let colDefIndex = _this.colDefinitions.findIndex(
              (x) =>
                x?.field?.key?.toLowerCase() == sheetDataCell?.v?.toLowerCase() ||
                x?.field?.name?.toLowerCase() == sheetDataCell?.v?.toLowerCase()
            );
            let colDef = colDefIndex != -1 ? _this.colDefinitions[colDefIndex] : null;
            if (colDef) {
              luckysheet.setCellValue(0, sheetCol.colIndex, colDef.field.key);
              if (!_this.colDefinitions[colDefIndex].boundedSheetColumns)
                _this.colDefinitions[colDefIndex].boundedSheetColumns = [];
              if (_this.colDefinitions[colDefIndex].fieldGroupKey) {
                if (_this.colDefinitions[colDefIndex].boundedSheetColumns.findIndex((x) => x.id == sheetCol.id) == -1) {
                  _this.colDefinitions[colDefIndex].boundedSheetColumns.push(sheetCol);
                }
              } else {
                _this.colDefinitions[colDefIndex].boundedSheetColumns = [sheetCol];
              }
              sheetCol.boundDefinition = _this.colDefinitions[colDefIndex];
              // sheetCol.validAmount = _this.calcValidationAmount(sheetCol.colName,colDef,sheetData);
            }
          }
        });
        _this.dropTargetCols.forEach((element) => {
          if (element.boundDefinition)
            element.validAmount = _this.calcValidationAmount(element.colName, element.boundDefinition, sheetData);
        });
        luckysheet.deleteRow(1, 1);
      },
    });
  }
  calcValidationAmount(key, colDefinition, sheetData) {
    let validAmount = 0;
    for (let i = 0; i < sheetData.length; i++) {
      const element = sheetData[i];
      if (this.isValid(element[key], colDefinition)) validAmount++;
    }
    return (validAmount * 100) / sheetData.length;
  }
  isValid(item, colDefinition: ImporterColumnDefinition) {
    //@TODO: duplicate validation cause there's no other way around it
    if (colDefinition.field.required) {
      return !!item?.v;
    }
    return true;
  }
}
